import { GetRequest, PostRequest, successParam } from '@/api/base';

const apiUrl = {
    getListForPmSubscription: 'v3/web/office/subscription/getListForPm',
    computedPriceSubscription: 'v3/web/office/subscription/computedPrice'
};

// getListForPmSubscription(params: object, callback: successParam) {
//     PostRequest(apiUrl.getListForPmSubscription, params, callback);
// }
export interface GetListForPmSubscription {
    total: number;
    row: {
        ID: number;
        OrderNumber: string;
        Status: number;
        TotalPrice: string;
        StartTime: string;
        EndTime: string;
        NextPayTime: string;
        CreateTime: string;
        IntervalType: number;
        Cycles: number;
        PayPlatform: number;
        UUID: string;
        TimeZone: string;
        IsBatch: number;
    }[];
}
export function getListForPmSubscription(params: object, callback: Function) {
    GetRequest(apiUrl.getListForPmSubscription, params, callback);
}

export interface ComputedPriceSubscription {
    Price: string;
}
export function computedPriceSubscription(params: object, callback: successParam, errorCallback: Function) {
    PostRequest(apiUrl.computedPriceSubscription, params, callback, errorCallback);
}

export interface GetInfoSubscription {
    ID: number;
    OrderNumber: string;
    Status: number;
    TotalPrice: string;
    StartTime: string;
    EndTime: string;
    NextPayTime: string;
    CreateTime: string;
    IntervalType: number;
    Cycles: number;
    PayPlatform: number;
    UUID: string;
    TimeZone: string;
    Reason: number;
    Items: {
        DepartmentName: string;
        Name: string;
        Role: string;
        Email: string;
        Amount: string;
        ID:	number;
    }[];
}
export function getInfoSubscription(params: object, callback: Function) {
    GetRequest('v3/web/office/subscription/getInfo', params, callback);
}

export function cancelSubscription(params: object, callback: successParam) {
    PostRequest('v3/web/office/subscription/cancelSubscription', params, callback);
}

export function delSubscription(params: object, callback: successParam) {
    PostRequest('v3/web/office/subscription/delSubscription', params, callback);
}
export interface GetListForInsSubscription {
    total: number;
    row: {
        ID: number;
        OrderNumber: string;
        Status: number;
        TotalPrice: string;
        StartTime: string;
        EndTime: string;
        NextPayTime: string;
        CreateTime: string;
        IntervalType: number;
        Cycles: number;
        PayPlatform: number;
        UUID: string;
        TimeZone: string;
    }[];
}
export default {};
