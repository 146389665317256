import { getStorage, setStorage } from './storage';

interface TableDefaultSort {
    prop: string;
    order: string;
}

interface TableSortStatus {
    SortField: string;
    Sort: string;
}

/**
 * 保存当前排序状态
 * @param tableName 表格名，应该具有唯一，和其他表格区分开
 * @param prop column的 prop
 * @param order 排序
 */
export function saveTableSortStatus(tableName: string, prop: string, order: string): TableSortStatus {
    const orderMap = { ascending: 'asc', descending: 'desc' };
    const tableSortStatus = getStorage('tableSortStatus');
    if (!tableSortStatus) {
        // @ts-ignore
        setStorage('tableSortStatus', { [`${tableName}`]: { SortField: prop, Sort: orderMap[order] } });
        // @ts-ignore
        return { SortField: prop, Sort: orderMap[order] };
    }
    // @ts-ignore
    tableSortStatus[tableName] = { SortField: prop, Sort: orderMap[order] };
    setStorage('tableSortStatus', tableSortStatus);
    // @ts-ignore
    return { SortField: prop, Sort: orderMap[order] };
}

/**
 * 获取当前排序状态，后端定义格式，提供给后端接口
 * @param tableName 表格名，应该具有唯一
 * @returns object:{SortField:string, Sort:string}
 */
export function getTableSortStatus(tableName: string): TableSortStatus {
    const tableSortStatus = getStorage('tableSortStatus');
    // @ts-ignore
    if (!tableSortStatus || !tableSortStatus[tableName]) {
        return { SortField: '', Sort: '' };
    }
    // @ts-ignore
    return tableSortStatus[tableName];
}

/**
 * 获取表格默认初始排序状态,提供给el-table 的default-sort属性
 * @param tableName 表格名，应该具有唯一
 * @returns defaultSort:{prop:string, order:string} | {}
 */
export function getDefaultSort(tableName: string): TableDefaultSort {
    let defaultSort = {
        prop: '',
        order: ''
    };
    const sortStatus = getTableSortStatus(tableName);
    if (sortStatus && sortStatus.Sort) {
        const orderMapReverse = { asc: 'ascending', desc: 'descending' };
        // @ts-ignore
        defaultSort = { prop: sortStatus.SortField, order: orderMapReverse[sortStatus.Sort] };
    }

    return defaultSort;
}

/**
 * 设置表格排序，通过前端
 * @param tableData 被排序的表格数据
 * @param orderData 排序字段和排序顺序
 * @returns 被排序后的数据
 */
export function setTableSortByFrontEnd(tableData: any[], orderData: {SortField: string; Sort: string}) {
    const sortCallBackFn = (obj1: any, obj2: any) => {
        const value1 = obj1[`${orderData.SortField}`];
        const value2 = obj2[`${orderData.SortField}`];
        if (value1 !== null && value2 !== null && value1.localeCompare(value2) > 0) {
            return 1;
        } if (value1 !== null && value2 !== null && value1.localeCompare(value2) < 0) {
            return -1;
        }
        return 0;
    };
    if (orderData.Sort) {
        if (orderData.Sort === 'desc') {
            return tableData.sort(sortCallBackFn).reverse();
        }
        return tableData.sort(sortCallBackFn);
    }
    return tableData;
}
